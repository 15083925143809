<template>
	<swiper
		class="select-products-block-swiper"
		:modules="[Pagination, Virtual]"
		:pagination="{
      el:'.swiper-pagination-mobile',
      enabled: true,
      type: 'bullets',
      dynamicBullets: Boolean(isUsedDynamicBullets)
    }"
		:init="true"
    :loop="(products || []).length > 1"
		:space-between="16"
		:slides-per-view="1.1"
		:breakpoints="{640: {slidesPerView: 2.2}}"
		@swiper="mobileSelectSliderRefSwiper.setSliderRef"
	>
    <swiper-slide
      v-for="(product) in products"
      :key="`product-${ product.slug }`"
      class="swiper-slide"
    >
      <div v-if="Boolean(product.characteristicGroupId === 19)">
        <CollectionCard :product="product"/>
      </div>
      <div v-else>
        <ProductCard :product="product"/>
      </div>
    </swiper-slide>
		<div class="swiper-pagination-mobile"/>
	</swiper>
</template>

<script setup lang="ts">
import type {IProduct} from '#sitis/internal/controllers/products/models/Product';
import {Swiper, SwiperSlide} from 'swiper/vue';
import type {Swiper as SwiperClass} from 'swiper/types';
import ProductCard from '~/components/catalog/ProductCard.vue';
import CollectionCard from '~/components/catalog/CollectionCard.vue';
import {setCustomSwiper} from '~/utils/swiper/setCustomSwiper';
import {Pagination, Virtual} from 'swiper/modules';

interface IProps {
	products: IProduct[]
}

const props = withDefaults(defineProps<IProps>(), {
	products: () => []
});

const mobileSelectSliderRef = ref<SwiperClass | null>(null);
const mobileSelectSliderRefSwiper = setCustomSwiper(mobileSelectSliderRef);

const isUsedDynamicBullets = computed(() => {
  return props.products.length > 20
})
const dynamycMainBulletCount = computed(() => {
  return props.products.length / 3;
})
</script>

<style lang="scss">
.select-products-block-swiper {
	margin: 0 -20px;
	padding: 0 20px;
	box-sizing: border-box;

  .swiper-slide {
    height: auto;
    display: flex;
    flex-direction: column;

    & > * {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .product-card {
      height: 100%;
    }
  }
	.swiper-pagination-mobile {
		position: relative;
		margin-top: 34px;

		& > * {
			opacity: 1;
			width: 10px !important;
			height: 10px !important;
			background-color: #EBEBEB;
      min-width: 10px;

			&.swiper-pagination-bullet-active {
				background-color: black;
			}

      &.swiper-pagination-bullet-active-main {
        background-color: black;
      }
		}
    &:not(.swiper-pagination-bullets-dynamic) {
      display: flex;
      justify-content: center;
    }
	}
}
</style>
